import addTrackingToStreamUrl from 'widget/Podcast/helpers/addTrackingToStreamUrl';
import invariant from 'invariant';
import PlayerContext from 'widget/contexts/Player/PlayerContext';
import useMount from 'hooks/useMount';
import useSession from 'widget/utils/session/hooks/useSession';
import { AxiosTransport } from 'widget/utils/transport/AxiosTransport';
import { COLLECTION, PODCAST, RADIO } from 'widget/constants/playback';
import { GetStreamInterface } from 'widget/services/playback/streams';
import { isPlsFile, playPlsStation } from './plsHelpers';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { usePlayerState } from 'widget/hooks';
import { v4 as uuid } from 'uuid';
import type {
  CurrentlyPlaying,
  RequestQuery,
  Tracks,
} from 'widget/contexts/Player/types';
import type { Playback } from 'widget/types';
import type { ResponsePayload as ReportingPayload } from 'widget/services/playback/reporting/types';
import type {
  ResponseIsError,
  ResponsePayload,
} from 'widget/services/playback/streams/types';

type Props = {
  children: ReactNode;
  getStream: GetStreamInterface;
  initialCurrentlyPlaying?: CurrentlyPlaying;
  playbackType: Playback;
  tracks: Tracks;
  transport: AxiosTransport;
};

function PlayerProvider({
  children,
  getStream,
  initialCurrentlyPlaying,
  playbackType,
  tracks,
  transport,
}: Props): any {
  const sessionInfo = useSession();
  const [playerState, loadNewTrack, togglePlay] = usePlayerState();
  const [currentlyPlaying, setCurrentlyPlaying] = useState<
    undefined | CurrentlyPlaying
  >(initialCurrentlyPlaying);
  const currentRetryIndex = useRef(0);
  const [isAA, setIsAA] = useState(false);
  const [cacheId, bustCache] = useState(uuid());
  const [reportingPayload, setReportingPayload] = useState(
    {} as ReportingPayload,
  );
  const [skipDisabled, setSkipDisabled] = useState(true);

  async function getPodcastStream(
    trackId: string,
    stationId: string,
    streamUrlQuery: RequestQuery,
  ) {
    invariant(
      stationId,
      `no stationId found matching track "${trackId}" for playback type ${playbackType}`,
    );

    const streams = await getStream(
      {
        contentIds: [trackId],
        playedFrom: 0,
        stationId,
        stationType: playbackType,
      },
      sessionInfo,
    );

    const { streamUrl } = (streams as ResponsePayload)?.items?.[0] ?? {};
    return {
      ...streams,
      streamUrl: addTrackingToStreamUrl(streamUrl, streamUrlQuery),
    };
  }

  async function getArtistRadioStream(stationId: string, identifier: string) {
    return getStream(
      {
        contentIds: [],
        playedFrom: 68,
        stationId,
        stationType: RADIO,
      },
      sessionInfo,
      identifier,
    );
  }

  async function getFavoritesRadioStream(
    stationId: string,
    identifier: string,
  ) {
    return getStream(
      {
        contentIds: [],
        playedFrom: 98,
        stationId,
        stationType: RADIO,
      },
      sessionInfo,
      identifier,
    );
  }

  async function getPlaylistRadioStream(
    stationId: string,
    trackIds: Array<number> = [],
    identifier: string,
  ) {
    const aaStationId = stationId.split('::')[1];
    return getStream(
      {
        contentIds:
          isAA ? [trackIds[Math.floor(Math.random() * trackIds.length)]] : [],
        playedFrom: 0,
        stationId: isAA ? aaStationId : stationId,
        stationType: COLLECTION,
      },
      sessionInfo,
      identifier,
    );
  }

  function setStream({
    isSkip,
    stationId,
    streams,
  }: {
    isSkip?: boolean;
    stationId: string;
    streams: ResponsePayload | ResponseIsError;
  }) {
    const stream = (streams as ResponsePayload)?.items?.[0] ?? {};
    const {
      content: {
        artistId,
        artistName = null,
        id: trackId,
        title: songName = '',
        imagePath: trackImage = null,
      } = {},
      reportPayload,
      streamUrl,
    } = stream;
    const isError =
      (streams as ResponseIsError)?.isError || !reportPayload || !streamUrl;

    setCurrentlyPlaying({
      artistId,
      artistName,
      isError,
      isSkip,
      playbackType,
      reportPayload: reportPayload!,
      songName,
      stationId,
      trackId: String(trackId || ''),
      trackImage,
    });

    currentRetryIndex.current = 0;

    invariant(
      !isError,
      `getStream for track/station ${trackId}/${stationId} failed`,
    );

    try {
      const url = new URL(streamUrl);
      url.searchParams.set('us_privacy', '1YYN');
      return url.toString();
    } catch {
      return streamUrl;
    }
  }

  async function play(
    stationId: string,
    opts?: {
      streamUrlQuery?: RequestQuery;
      trackId?: string | null;
      trackIds?: Array<number>;
    },
  ) {
    invariant(__CLIENT__, 'play should only be called in the browser');

    const { streamUrlQuery, trackId, trackIds } = opts ?? {};

    invariant(
      trackId || playbackType !== PODCAST,
      `trackId required and to be of type string, received: type ${typeof trackId} with value ${trackId}`,
    );

    const streams =
      playbackType === PODCAST ?
        await getPodcastStream(
          trackId!,
          stationId,
          streamUrlQuery as RequestQuery,
        )
      : await getPlaylistRadioStream(stationId, trackIds, cacheId);
    const streamUrl = setStream({ isSkip: false, stationId, streams });

    if (
      !currentlyPlaying ||
      trackId === currentlyPlaying.trackId ||
      playbackType !== PODCAST
    ) {
      togglePlay(streamUrl!);
    } else {
      loadNewTrack(streamUrl!);
    }
  }

  async function playNextPodcast(stationId: string, origQuery: RequestQuery) {
    if (!tracks.length || !currentlyPlaying || !currentlyPlaying.trackId) {
      return Promise.reject(new Error('no tracks to play'));
    }

    const retryIndex = currentRetryIndex.current;

    const currentTrackId = Number(currentlyPlaying.trackId);
    const tracksArray = tracks.map(t => Number(t.trackId));
    const currentTrackIndex =
      tracksArray.indexOf(currentTrackId as number) + retryIndex;
    const nextTrack = tracksArray[currentTrackIndex + 1];
    const fetchTrack =
      currentTrackIndex < tracks.length - 1 ? nextTrack : tracksArray[0];

    try {
      const streams = await getPodcastStream(
        String(fetchTrack),
        stationId,
        origQuery,
      );
      const streamUrl = setStream({ isSkip: false, stationId, streams });
      loadNewTrack(streamUrl);
    } catch {
      currentRetryIndex.current = retryIndex + 1;
      return Promise.reject(new Error('could not get next podcast episode'));
    }
    return Promise.resolve(true);
  }

  async function playArtistStation(stationId: string) {
    invariant(__CLIENT__, 'play should only be called in the browser');

    invariant(
      stationId,
      `stationId required and to be of type string, received: type ${typeof stationId} with value ${stationId}`,
    );

    const streams = await getArtistRadioStream(stationId, cacheId);
    const streamUrl = setStream({
      isSkip: false,
      stationId,
      streams,
    });

    togglePlay(streamUrl!);
  }

  async function playFavoritesStation(stationId: string) {
    invariant(__CLIENT__, 'play should only be called in the browser');

    invariant(
      stationId,
      `stationId required and to be of type string, received: type ${typeof stationId} with value ${stationId}`,
    );

    const streams = await getFavoritesRadioStream(stationId, cacheId);
    const streamUrl = setStream({
      isSkip: false,
      stationId,
      streams,
    });

    togglePlay(streamUrl!);
  }

  function playLiveStation(
    liveId: string,
    stream: { type: string; url: string },
    isFallback?: boolean,
  ) {
    invariant(__CLIENT__, 'play should only be called in the browser');

    invariant(
      liveId,
      `trackId required and to be of type string, received: type ${typeof liveId} with value ${liveId}`,
    );

    const playLiveFunction = isFallback ? loadNewTrack : togglePlay;

    if (isPlsFile(stream.url)) {
      playPlsStation(stream.url, transport, playLiveFunction);
    } else {
      playLiveFunction(stream.url);
    }
  }

  async function playNextPlaylistRadio(
    stationId: string,
    isSkip: boolean,
    trackIds?: Array<number>,
  ) {
    const newCacheId = uuid();
    bustCache(newCacheId);
    try {
      const streams = await getPlaylistRadioStream(
        stationId,
        trackIds,
        newCacheId,
      );
      const streamUrl = setStream({ isSkip, stationId, streams });
      loadNewTrack(streamUrl);
    } catch {
      return Promise.reject(new Error('could not resolve stream'));
    }
    return Promise.resolve(true);
  }

  async function playNextArtistRadio(stationId: string, isSkip: boolean) {
    const newCacheId = uuid();
    bustCache(newCacheId);
    try {
      const streams = await getArtistRadioStream(stationId, newCacheId);
      const streamUrl = setStream({
        isSkip,
        stationId,
        streams,
      });
      loadNewTrack(streamUrl);
    } catch {
      return Promise.reject(new Error('could not resolve stream'));
    }
    return Promise.resolve(true);
  }

  async function playNextFavoritesRadio(stationId: string, isSkip: boolean) {
    const newCacheId = uuid();
    bustCache(newCacheId);
    try {
      const streams = await getFavoritesRadioStream(stationId, newCacheId);
      const streamUrl = setStream({
        isSkip,
        stationId,
        streams,
      });
      loadNewTrack(streamUrl);
    } catch {
      return Promise.reject(new Error('could not resolve stream'));
    }
    return Promise.resolve(true);
  }

  useEffect(() => {
    const { daySkipsRemaining = 0, hourSkipsRemaining = 0 } =
      reportingPayload ?? {};
    const skipsRemaining = daySkipsRemaining > 0 && hourSkipsRemaining > 0;
    setSkipDisabled(!skipsRemaining);
  }, [reportingPayload]);

  const providerProps = {
    getCurrentlyPlaying() {
      return currentlyPlaying;
    },
    play,
    playArtistStation,
    playerState,
    playFavoritesStation,
    playLiveStation,
    playNextArtistRadio,
    playNextFavoritesRadio,
    playNextPlaylistRadio,
    playNextPodcast,
    skipDisabled,
    setReportingPayload,
  };

  useMount(() => {
    setIsAA(
      window?.analyticsData?.global?.user?.subscriptionTier === 'PREMIUM',
    );
  });

  return useMemo<any>(
    () => (
      <PlayerContext.Provider value={providerProps}>
        {children}
      </PlayerContext.Provider>
    ),
    [providerProps, children],
  );
}

export default PlayerProvider;
