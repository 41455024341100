import createAuthHeaders from 'widget/services/utils/createAuthHeaders';
import createTaggedUrl from 'api/createTaggedUrl';
import invariant from 'invariant';
import safeStringify from 'utils/safeStringify';
import {
  ReportRequest,
  RequestHeaders,
  RequestPayload,
  ResponseIsError,
  ResponsePayload,
} from 'widget/services/playback/streams/types';
import { TransportInterface } from 'widget/utils/transport/types';

type Props = {
  ampUrl?: string;
  transport: TransportInterface;
};

async function getPlaylistData({
  contentIds,
  hostName,
  limit,
  playedFrom,
  requestName,
  sessionInfo,
  stationId,
  stationType,
  transport,
  url,
}: ReportRequest) {
  let data = {} as ResponsePayload | ResponseIsError;
  try {
    const res = await transport.post(
      url,
      {
        contentIds,
        hostName,
        limit,
        playedFrom,
        stationId,
        stationType,
      },
      {
        headers: createAuthHeaders(sessionInfo),
        params: { requestName },
      },
    );
    data = res.data;
  } catch (_e) {
    data = { isError: true };
  }
  return data;
}

/**
 * playbackStreamService calls out to amp to return the specified contentIds
 * mediaUrls and reportingPayloads. This should be called upon the client as it
 * requires auth headers to do as such
 *
 * Swagger Docs:
 *   http://ampinternal.ihrprod.net/api/apidocs/#!/playback/getStreams
 */
function playbackStreamService({ ampUrl = '', transport }: Props) {
  invariant(
    __CLIENT__,
    'playback stream service should not be called on the server',
  );

  async function postPlaybackStreams(
    {
      contentIds,
      hostName = 'webapp.US',
      limit = 1,
      playedFrom = 0,
      stationId,
      stationType,
    }: RequestPayload,
    sessionInfo: RequestHeaders,
    _identifier?: string,
  ): Promise<ResponsePayload | ResponseIsError> {
    invariant(
      contentIds && Array.isArray(contentIds),
      `contentIds expected to be an array of strings, received ${safeStringify(
        contentIds,
      )}`,
    );

    invariant(
      stationId && typeof stationId === 'string',
      `stationId expected to be string, received: ${safeStringify(stationId)}`,
    );

    invariant(
      stationType && typeof stationType === 'string',
      `stationType expected to be string, received: ${safeStringify(
        stationType,
      )}`,
    );

    invariant(
      sessionInfo &&
        sessionInfo.profileId &&
        sessionInfo.sessionId &&
        typeof sessionInfo.profileId === 'string' &&
        typeof sessionInfo.sessionId === 'string',
      `sessionInfo of incorrect type, received: ${safeStringify(sessionInfo)}`,
    );

    const [url, requestName] = createTaggedUrl`${{
      ampUrl,
    }}/api/v2/playback/streams`;

    return getPlaylistData({
      contentIds,
      hostName,
      limit,
      playedFrom,
      requestName,
      sessionInfo,
      stationId,
      stationType,
      transport,
      url,
    }) as Promise<ResponsePayload | ResponseIsError>;
  }

  // we want to avoid duplicate calls to streams service for a given station/track tuple,
  // each call returns a unique reportPayload
  let instance: {
    [index: string]: Promise<ResponsePayload | ResponseIsError>;
  } = {};

  return async function instanceCreator(
    requestPayload: RequestPayload,
    requestHeaders: RequestHeaders,
    identifier?: string,
  ): Promise<ResponsePayload | ResponseIsError> {
    const cacheKey = `${requestPayload?.stationId}/${
      requestPayload?.contentIds?.[0] || identifier
    }`;

    if (await instance[cacheKey]) return instance[cacheKey];

    instance = {};
    const initializedPlaybackStream = postPlaybackStreams(
      requestPayload,
      requestHeaders,
    );

    instance[cacheKey] = initializedPlaybackStream;
    return initializedPlaybackStream;
  };
}

export default playbackStreamService;
