import qs from 'qs';
import { Request } from 'express';

// IHRWEB-14922: for podcast producers to provide attribution to plays/downloads through iHeartRadio
function addTrackingToStreamUrl(
  streamUrl: string,
  pageLevelQuery: Request['query'],
) {
  if (!streamUrl) return streamUrl;

  const utmMedium = pageLevelQuery?.utm_medium ?? null;
  let queryString = '';
  if (utmMedium) {
    const properQuery = streamUrl.includes('?') ? '&' : '?';
    const trackingData: Request['query'] = {};
    trackingData.utm_medium = utmMedium;
    queryString = properQuery + qs.stringify(trackingData);
  }

  return streamUrl.concat(queryString);
}

export default addTrackingToStreamUrl;
