import { AxiosTransport } from 'widget/utils/transport/AxiosTransport';
import { createCacheBust, getSid } from './helpers';
import { Events } from '../types';
import type { EventTypeMap } from '../types';
import type { Session } from 'widget/utils/session';
import type { TrackerConfig } from '@iheartradio/web.signal';

export type TritonIds = {
  custom: string;
  talk: string;
};

const tritonLTTracker = (
  enabled: boolean,
  config: TritonIds,
  session: Session,
): TrackerConfig<EventTypeMap> => {
  let pingInterval = 6000;
  let tritonInterval: number | undefined;
  let tritonGuid: string | undefined;

  /**
   * RequestURL (HTTP GET):https://sampleURL.tritondigital.com/lt?sid={sid}&vid={vid}&cb={cb}&dev={dev}&dist={dist}&ss={ss}&ps={ps}&autoplay={0/1}&hasads={0/1}&ctype={ctype}
   * Example:https://publisherABC.tritondigital.com/lt?sid=1234&vid=10001256&cb=32226688&dev=android&dist=addregatorXYZ&ss=publisherABC&ps=thisandroidapp&autoplay=0&hasads=1&ctype=live
   */
  const tritonUrl = 'https://lt110.tritondigital.com/lt';
  const transport = new AxiosTransport({
    baseURL: tritonUrl,
  });

  let sid: string; // triton station type id
  let vid: string; // profileId
  let isTracking = false;
  let isMuted = false;

  // we ping triton on the ping interval with the guid from the start tracking response
  // us_privacy string is hardcoded to be opted out regardless, according to IHRWEB-20946
  const pingTriton = () => {
    if (tritonGuid) {
      transport.get('', {
        params: {
          guid: tritonGuid,
          cb: createCacheBust().toString(),
          us_privacy: '1YYN',
        },
      });
    }
  };

  // stop tracking and prepare for a new stream start by clearing the ping interval and guid
  const endTracking = () => {
    isTracking = false;
    clearInterval(tritonInterval);
    tritonInterval = undefined;
    pingTriton(); // one last ping to mark the end of the stream
    tritonGuid = undefined;
  };

  // start tracking a new stream
  const startTritonTracking = async () => {
    endTracking(); // clear old ping intervals
    if (sid) {
      isTracking = true;
      const { data } = await transport.get('', {
        params: {
          sid,
          vid,
          cb: createCacheBust().toString(),
        },
      });
      // triton returns a ping interval (seconds) and unique id as a comma delimited string
      const [pingIntervalSeconds, guid] = data.split(',');
      if (pingIntervalSeconds)
        pingInterval = parseInt(pingIntervalSeconds, 10) * 1000;
      if (guid) tritonGuid = guid;
      // per triton spec, we send a ping using the guid on the interval. We clear the interval if the stream pauses, stops, or mutes
      tritonInterval = window.setInterval(pingTriton, pingInterval);
    }
  };

  const mute = () => {
    isMuted = true;
    endTracking();
  };

  const unmute = ({ isPlaying }: EventTypeMap[typeof Events.Unmute]) => {
    isMuted = false;
    if (!isTracking && isPlaying) {
      startTritonTracking();
    }
  };

  // event handler for new streams: play, unpause
  const startTracking = async (
    opts: EventTypeMap[typeof Events.StreamStart],
  ) => {
    const { type = '' } = opts;
    sid = getSid(type, config) ?? '';
    vid = String((await session.getSession()).profileId);

    if (!isTracking && !isMuted) {
      startTritonTracking();
    }
  };

  // this is an unusual signal implementation. We do not need to load a vendor script or add anything to global/window
  // we only need to listen to events
  return {
    enabled,
    name: 'TritonLT',
    initialize: () => {},
    events: {
      [Events.StreamStart]: startTracking,
      [Events.Play]: startTracking,
      [Events.Unmute]: unmute,
      [Events.Mute]: mute,
      [Events.Pause]: endTracking,
      [Events.Stop]: endTracking,
      [Events.StreamEnd]: endTracking,
    },
  };
};

export default tritonLTTracker;
